import {BasicsClass} from "@/common/BasicsClass";
import {Util} from "@/common/util";

export default class RevenueReportAPIClass extends BasicsClass {
    public apiUrls: any = new Map([
        ['getList', {
            url: '/stat/businessReport',
            name: 'list',
            label: '列表'
        }],

    ])

    public businessReport(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('getList').url
        const start_date: string = Util.dateUtil(params.time[0])
        const end_date: string = Util.dateUtil(params.time[1])
        this.BasicPost(
            url,
            {
                ...params,
                start_date: start_date,
                end_date: end_date
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

}
